import React from "react";
import logo from "../res/logo_short.png";

export default class SideNav extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="side-nav">
            <div className="side-nav-header">
                <img className="side-nav-logo" src={logo}/>
            </div>

            <ul className="side-nav-body">
                {this.props.elements.map((e,i) => {
                    return <li className="side-nav-el" key={i}>
                        <a onClick={el => {
                            el.preventDefault();
                            e.action();
                        }}>
                          <span><i className="material-icons align-middle">{e.icon}</i></span>
                         <span className={`${e.active?"active":""}`}>{e.text}</span>
                        </a>
                    </li>
                })}
            </ul>
        </div>
    }
}