import React,{lazy,Suspense} from 'react';
import "./styles/css/app.css";
import {Position, Toast, Toaster, Classes,Alert, Intent} from "@blueprintjs/core";
import firebase from "./utilities/firebaseSetUp";
import CircleLoader from "./components/CircleLoader";
import SideNav from "./components/SideNav";

import ModalProposalRender from './components/ModalProposalRender';

const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));



class App extends React.Component {
  _mounted;
  toaster;
  refHandlers;
  constructor(props){
    super(props);
    this.state = {
      proposalRender:false,
      screen:0,
      dashScreen:0,
      toasts:[],
      user:null
    }

    this.toaster = {};
    this.refHandlers = {
        toaster:(ref) => {this.toaster = ref},
    }

  }

  addToast = (message) => {
    this.toaster.show({message: message});
}

  componentDidMount(){
    this._mounted = true;

    console.log(window.location.href)

    let searchParams = new URLSearchParams(window.location.search);
    

    console.log(searchParams.has("proposalID"))
    if(searchParams.has("proposalID")){
      if(this._mounted){
        this.setState({
          proposalRender:true
        })
      }
    }

    firebase.auth().onAuthStateChanged(user => {
      if(user){
      
        firebase.firestore().collection("adminUsers").doc(user.uid).get()
        .then(doc =>{
          if(doc.exists){
            let data =doc.data();
            data.id = doc.id
            if(this._mounted){
              this.setState({
                screen:1,
                dashScreen:0,
                user:data
              })
            }
          }else {
            this.addToast("No existe el usuario")
          }
        })
        .catch(e => {
          console.log(e);
          this.addToast("Algo salió mal");
        })
      }else {
        //User sign out 
        if(this._mounted){
          this.setState({
            screen:0,
            user:null,
            dashScreen:0
          })
        }
      }
    })
  }

  componentWillUnmount(){
    this._mounted = false;
  }


  render(){
    return <div>
      {this.state.screen > 0 && this.state.proposalRender == false?<SideNav
      elements={[
        {
          icon:"home",
          text:"INICIO",
          active:this.state.dashScreen === 0?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:0
              })
            }
          }
        },
        {
          icon:"accessibility",
          text:"LEADS",
          active:this.state.dashScreen === 1?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:1
              })
            }
          }
        },
        {
          icon:"near_me",
          text:"OFERTADAS",
          active:this.state.dashScreen === 8?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:8
              })
            }
          }
        },
        {
          icon:"local_shipping",
          text:"EMPRESAS",
          active:this.state.dashScreen === 2?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:2
              })
            }
          }
        },
        {
          icon:"important_devices",
          active:this.state.dashScreen === 3?true:false,
          text:"ITEMS WEB",
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:3
              })
            }
          }
        },
        {
          icon:"star_half",
          text:"EN PROCESO",
          active:this.state.dashScreen === 4?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:4
              })
            }
          }
        },
        {
          icon:"star",
          text:"TERMINADAS",
          active:this.state.dashScreen === 5?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:5
              })
            }
          }
        },
        {
          icon:"payments",
          text:"PAGOS",
          active:this.state.dashScreen === 10?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:10
              })
            }
          }
        },
        {
          icon:"smartphone",
          text:"ITEMS APP",
          active:this.state.dashScreen === 6?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:6
              })
            }
          }
        },
        {
          icon:"mood",
          text:"REVIEWS",
          active:this.state.dashScreen === 9?true:false,
          action:() => {
            if(this._mounted){
              this.setState({
                dashScreen:9
              })
            }
          }
        },
        {
          icon:"settings",
          text:"SETTINGS",
          active:this.state.dashScreen === 7?true:false,
          action:() => {alert("Yay!")}
        }
      ]}
      />:null}
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map((toast) => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
      <Suspense fallback={<CircleLoader/>}>
        {this.state.proposalRender?<ModalProposalRender addToast={this.props.addToast} close={() => {
          
        }}/>:null}
        {this.state.screen === 0 && this.state.proposalRender === false?<Login
        setCredentials={(email, pass) => {
          if(this._mounted){
            
            this.setState({
              user:{
                email:email,
                pass:pass
              }
            })
          }
        }}
        addToast={this.addToast}
        />:null}

        {this.state.screen === 1 && this.state.proposalRender == false?<Dashboard
        user={this.state.user}
        setDashScreen={(n) => {
          if(this._mounted){
            this.setState({
              dashScreen:n
            })
          }
        }}
        screen={this.state.dashScreen}
        addToast={this.addToast}
        />:null}
      </Suspense>
    </div>
  }
}

export default App;
