import React from "react";
import firebase from "../utilities/firebaseSetUp";

import formatNumber from "../utilities/formatNumber";

import MUDANZASTSS from "../res/MUDANZASTSS.png";

import logo_short from "../res/logo_short.png";

export default class ModalProposalRender extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            lead:null,
            proposal:null,
            company:null,
            mudapartnerPhoto:"",
            iva:""
        }
    }

    componentDidMount(){
        this._mounted = true;
        this.getData()
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    formatDate = (d) => {
        const fix = (n) => {
            let result = String(n);

            for(let i =0; i < (2 - String(n).length); i++){
                result = "0" + String(n)
            }

            return result
        }

        return `${fix(d.getDate())}-${fix(d.getMonth() + 1)}-${d.getFullYear()}`
    }

    getData = () => {
        let searchParams = new URLSearchParams(window.location.search);

        let proposalID = "";

        if(searchParams.has("proposalID")){
            proposalID = searchParams.get("proposalID")
        }

        if(proposalID !== ""){
            firebase.firestore().collection("proposals").doc(proposalID).get()
            .then(doc => {
                let data =doc.data();
                data.id = doc.id;

                console.log(data);

                if(this._mounted){
                    this.setState({
                        proposal:data,
                        iva:data.iva?data.iva:false
                    }, () => {
                        firebase.firestore().collection("users").doc(data.driverID).get()
                        .then(doc => {

                            if(this._mounted){
                                this.setState({
                                    mudapartnerPhoto:doc.data().photoURL !== undefined?doc.data().photoURL:""
                                })
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            this.props.addToast("Algo salió mal con el usuario")
                        })
                    })
                }
            })
            .catch(e => {
                console.log(e);
                this.props.addToast("Algo salió mal buscando la cotización")
            })
        }

        let leadID = "";

        if(searchParams.has("leadID")){
            leadID = searchParams.get("leadID")
        }

        if(leadID !== ""){
            firebase.firestore().collection("leads").doc(leadID).get()
            .then(doc => {
                let data =doc.data();
                data.id = doc.id;

                if(this._mounted){
                    this.setState({
                        lead:data
                    })
                }
            })
            .catch(e => {
                console.log(e);
                this.props.addToast("Algo salió mál buscando la solicitud de mudanza")
            })
        }


    }

    render(){
        return <div className="modal-home-wrapper">
            <button className="btn btn-close-modal" onClick={() => {
                this.props.close();
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>

            <div className="modal-home">

                {this.state.lead == null && this.state.proposal == null?<div className="flex-center">
                    <div className="spinner-border"></div>
                </div>:this.state.lead !== null && this.state.proposal != null?<div>

                <div className="field-element">
                      

                      <div className="row">
                          <div className="col-sm-6">
                            <img src={this.state.mudapartnerPhoto !== ""?this.state.mudapartnerPhoto:MUDANZASTSS} className="logo-mudapartner"/>
                          </div>

                          <div className="col-sm-6 flex-end">
                            <img src={logo_short}/>
                          </div>
                      </div>

                      <div className="row mt-3">
                          <div className="col-sm-6">
                             <p className=" header mb-0"><b>Empresa de Mudanzas:</b> {this.state.proposal.driverCompany}</p>
                          </div>

                          <div className="col-sm-6">
                          <p className=" mb-0 header text-right"><b>Fecha:</b> {this.formatDate(this.state.proposal.created.toDate())}</p>

                          <p className=" mb-0 mt-2 header text-right"><b>Lead:</b> {this.state.lead.leadNumber}</p>
                          </div>
                      </div>
                   

                
              </div>

              <div className="field-element">
                  <p className="header mb-0">
                  Nos permitimos presentar a su amable consideración nuestra cotización para transportar su menaje de casa, mobiliario de oficina, vehículo u otra carga afin. Tenemos el compromiso de cumplir cada uno de los servicios que usted adquirió en el momento de la venta, así como mantener la integridad de sus bienes.
                  </p>
              </div>

              <div className="field-element">
                  <div className="flex-between-2">
                      <p className="header mb-0"><b>Pago por apartado de fecha y espacio:</b></p>

                      <p className="header mb-0"><b>${this.state.ivaSwitch?formatNumber(Number(this.state.proposal.companyCut) + Number(this.state.proposal.companyCut) * 0.16):formatNumber(Number(this.state.proposal.companyCut))}</b></p>
                  </div>

                  <div className="flex-between-2 mt-3">
                      <p className="header mb-0"><b>Pago del anticipo:</b></p>

                      <p className="header mb-0"><b>${this.state.ivaSwitch?formatNumber(((Number(this.state.proposal.totalPrice) + Number(this.state.proposal.totalPrice) * 0.16) -  (Number(this.state.proposal.companyCut) + (Number(this.state.proposal.companyCut) * 0.16)))/2):formatNumber(((Number(this.state.proposal.totalPrice)) - Number(this.state.proposal.companyCut))/2)}</b></p>
                  </div>

                  <div className="flex-between-2 mt-3">
                      <p className="header mb-0"><b>Pago a la llegada:</b></p>

                      <p className="header mb-0"><b>${this.state.ivaSwitch?formatNumber(((Number(this.state.proposal.totalPrice) + Number(this.state.proposal.totalPrice) * 0.16) -  (Number(this.state.proposal.companyCut) + (Number(this.state.proposal.companyCut) * 0.16)))/2):formatNumber(((Number(this.state.proposal.totalPrice)) - Number(this.state.proposal.companyCut))/2)}</b></p>
                  </div>
              </div>

              <div className="field-element">
                  <div className="flex-between-2">
                      <p className="mb-0 header"><b>IVA:</b></p>

                      <p className="mb-0 header"><b>{this.state.iva}</b></p>
                  </div>
              </div>

              <div className="field-element">
                  <div className="flex-between-2">
                      <p className="mb-0 header"><b>Total:</b></p>

                      <p className="mb-0 header"><b>${this.state.ivaSwitch?`${formatNumber(Number(this.state.proposal.totalPrice) + (Number(this.state.proposal.totalPrice) * 0.16))}`:Number(this.state.proposal.totalPrice)}</b></p>
                  </div>
              </div>

              <div className="field-element">
                  <p className="header mb-0"><b>Nombre:</b> {this.state.lead.clientName}</p>

                  <p className="header mt-3 mb-0"><b>Email:</b> {this.state.lead.clientEmail}</p>

                  <p className="header mt-3 mb-0"><b>Celular:</b> {this.state.lead.clientPhone}</p>
              </div>

              <div className="field-element mt-2">
              <p className="header"><b>Origen:</b> {this.state.lead.originAddress}</p>

              <p className="header mt-2"><b>Tipo de inmueble:</b> {this.state.lead.buildingType}</p>

              <p className="header mt-2"><b>Distancia al estacionamiento:</b> {this.state.lead.distanceToEntrance}</p>
          </div>

          <div className="field-element mt-2">
              <p className="header"><b>Destino:</b> {this.state.lead.destinationAddress}</p>

              <p className="header mt-2"><b>Tipo de inmueble:</b> {this.state.lead.buildingType2}</p>

              <p className="header mt-2"><b>Distancia al estacionamiento:</b> {this.state.lead.distanceToEntrance2}</p>
          </div>

          <div className="field-element mt-2">
              <p className="header mb-0"><b>Fecha de recolección:</b> {this.state.proposal.recolectionDate}</p>

              <p className="header mb-0 mt-3"><b>Fecha de entrega:</b> {this.state.proposal.deliveryDate}</p>
          </div>

          <div className="field-element mt-2">
              <p className="header mb-0"><b>Tipo de servicio:</b> {this.state.lead !== null?(() => {
                  let selected = "";

                  for(let i=0; i < this.state.lead.details.length; i++){
                      if(this.state.lead.details[i].selected){
                          selected = this.state.lead.details[i].title
                      }
                  }

                  return selected
              })():<div className="spinner-border"></div>}</p>
          </div>

          <div className="field-element mt-2">
              <p className="mb-0 header"><b>Unidad de transporte:</b> {this.state.proposal.unitText}</p>
          </div>

          <div className="field-element mt-2">
              <p className="mb-0 header"><b>Personal:</b> {this.state.proposal.staffText}</p>
          </div>

          <div className="field-element mt-2">
              <p className="header mb-0"><b>Este servicio incluye:</b></p>

              {this.state.pendingBenefits?<div className="mt-3 flex-left">
                  <div className="spinner-border"></div>
              </div>:<div className="mt-3">{(() => {
                  let result = [];

                  let row = [];
                  let count = 0;
                  for(let i=0; i < this.state.proposal.includedServices.length; i++){

                      let currentCount = count
                      if(currentCount < 2){
                         // let math = Math.random() * 10000
                          row.push(<div className="col-sm-4">
                              <div className="flex-left" key={i}>
                              {this.state.proposal.includedServices[i].state === undefined || this.state.proposal.includedServices[i].state === false?<i className="material-icons align-middle mr-2">clear</i>:<i className="material-icons align-middle mr-2">done</i>}
                              {this.state.proposal.includedServices[i].name}
                          </div>
                              </div>)

                     count++;
                      }

                      if(currentCount === 2){
                       //   let math = Math.random() * 10000
                          row.push(<div className="col-sm-4">
                          <div className="flex-left" key={i}>
                              {this.state.proposal.includedServices[i].state === undefined || this.state.proposal.includedServices[i].state === false?<i className="material-icons align-middle mr-2">clear</i>:<i className="material-icons align-middle mr-2">done</i>}
                              {this.state.proposal.includedServices[i].name}
                          </div>
                          </div>)

                          result.push(<div className="row">{row}</div>);
                          row = [];
                          count= 0;
                      }

                      

                  }

                  if(count !== 0){
                      result.push(<div className="row">{row}</div>);
                  }

                  return result

              })()}</div>}
          </div>


          <div className="field-element mt-2">

              <div className="flex-between">
              <div>
              <p className="header"><b>Inventario:</b></p>
          {this.props.lead !== null && true !== true?<p className="value mt-">Volumen: {this.state.inventory.vol}</p>:null}
              {this.state.lead !== null?this.state.lead.inventory?(() => {
                  let vehicles = [...this.state.lead.vehicles]

                
          
                  let inventory = [...this.state.lead.inventory];

                  inventory = inventory.concat(vehicles)
                 
                  return inventory
              })().map((e,i) => {
                  return <p className={`value ${i === 0?"mt-3":"mt-2"}`} key={i}><b>{e.quantity} {e.title}</b></p>
              }):null:<div className="spinner-border"></div>}
              </div>

             
              </div>
          </div>

          <div className="field-element mt-2">

                 
                    <p className="header">Volumen aproximado</p>
                {this.state.lead !== null?<p className="header mt-3"><b>{this.state.lead.vol} m3</b></p>:null}
                </div>

                <div className="field-element mt-2">

                 
<p className="header">Observaciones</p>
{this.state.lead !== null?<p className="header mt-3"><b>{this.state.proposal.comment !== undefined?this.state.proposal.comment:""}</b></p>:null}
</div>

   <div className="field-element mt-2">
   <b>Importante</b> esta cotización se ha realizado con base a la información que el cliente ha proporcionado, en caso de excedentes en el inventario, maniobras y en acarreos el precio será ajustado
   </div>


                </div>
                :null}
            </div>
        </div>
    }
}